import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "routes/AppRoutes";
import { SessionProvider } from "providers/SessionProvider";
import { QuizProvider } from "providers/QuizProvider";
import "./App.css";

function App() {
  return (
    <Router>
      <SessionProvider>
                <QuizProvider>
        <AppRoutes />
                </QuizProvider>
      </SessionProvider>
    </Router>
  );
}

export default App;
